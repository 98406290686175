import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="1f612a2cc0723ff23eec7b4632da5b55d7a46035"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werklast
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="parent-active last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                  <li className="active last">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                      Recepten
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-19">
            <h1>Recepten voor een evenwichtige voeding</h1>
            <h4>Bessenshake met limoen</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrediënten voor 4 porties:</strong>
                </p>
                <ul>
                  <li>100 g blauwe bosbessen</li>
                  <li>50 g zwarte aalbessen</li>
                  <li>400 ml sojamelk (bv. met calcium)</li>
                  <li>1 snuifje kaneel</li>
                  <li>1 onbehandelde limoen (schil, sap)</li>
                  <li>2 el honing</li>
                  <li>een beetje verse citroenmelisse</li>
                </ul>
                <p>
                  <strong>Benodigde tijd:</strong>
                  <br />
                  Voorbereiding: 15 min
                  <br />
                  Koken/koelen: 0 min
                  <br />
                  Bereiding: 15 min
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/bessenshake.jpeg"
                  style={{
                    width: "220px",
                    height: "330px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Bereiding:</strong>
              <br />
              Spoel de bessen kort af met koud water, zoek ze uit en laat ze op
              een doek uitlekken. Meng de vruchten met zeer goed gekoelde
              sojamelk, kaneel, limoensap en honing. Pureer met de pureerstaaf
              of in de mixer. Spoel de melisse af en schud droog. Trek de
              blaadjes van de stengels. Verdeel de shake over twee glazen.
              Versier met limoenschil en melisse.
            </p>
            <h4>Aspergecarpaccio met kalkoenborst</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrediënten voor 4 porties</strong>
                </p>
                <ul>
                  <li>300 g kalkoenborstfilet</li>
                  <li>150 g groene asperges</li>
                  <li>150 g witte asperges</li>
                  <li>50 g cocktailtomaten</li>
                  <li>30 g raketsla, versnipperd</li>
                  <li>20 g zoete mosterd</li>
                  <li>1 tl rode peperkorrels</li>
                  <li>50 ml olijfolie</li>
                  <li>1 takje kervel</li>
                  <li>zout en peper uit de molen</li>
                  <li>sap van een kleine citroen</li>
                </ul>
                <p>
                  <strong>Benodigde tijd:</strong>
                  <br />
                  Voorbereiding: 40 min.
                  <br />
                  Koken/koelen: 0 min.
                  <br />
                  Bereiding: 40 min.
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/spargelcarpacio.jpeg"
                  style={{
                    width: "250px",
                    height: "248px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Bereiding:</strong>
              <br />
              Schil de witte en groene asperges. Snijd het onderste gedeelte
              eraf en kook ze beetgaar in gezouten water. Spoel ze af met koud
              water. Leg de asperges 30 minuten in het diepvriesvak. Snijd de
              licht bevroren asperges zoals carpaccio in zeer dunne reepjes en
              kruid ze met zout en peper. Was de tomaten en snijd ze in vier.
              Snijd de fijne kalkoenborstfilet ook in dunne reepjes en voeg die
              bij de asperges. Besprenkel met het sap van de citroen en met de
              olijfolie. Versier met kervel en enkele peperkorrels.
              <br />
              Voedingswaarde: per portie: 18 g eiwitten, 14 g vetten, 3 g
              koolhydraten, 218 kcal
            </p>
            <h4>Zalmforelfilet met groenten</h4>
            <div className="row">
              <div className="eight columns">
                <p>
                  <strong>Ingrediënten voor 4 porties:</strong>
                </p>
                <ul>
                  <li>800 g zalmforelfilet</li>
                  <li>2 tomaten</li>
                  <li>1 kleine courgette</li>
                  <li>200 g champignons</li>
                  <li>1/2 kleine bleekselderij</li>
                  <li>zeezout</li>
                  <li>peper uit de molen</li>
                  <li>1 el walnootolie</li>
                  <li>peterselie</li>
                </ul>
                <p>
                  <strong>Benodigde tijd:</strong>
                  <br />
                  Voorbereiding: 30 min.
                  <br />
                  Koken/koelen: 0 min.
                  <br />
                  Bereiding: 30 min.
                </p>
              </div>
              <div className="four columns">
                <img
                  alt
                  src="/assets/files/pages/Saiblingfilet.jpeg"
                  style={{
                    width: "220px",
                    height: "293px"
                  }}
                />
              </div>
            </div>
            <p>
              <strong>Bereiding:</strong>
              <br />
              Maak de groenten en de peterselie schoon en was ze. Snijd de
              tomaten in acht stukken, de courgettes en de champignons in
              ongeveer 0,5 cm dikke schijfjes, de bleekselderij in 1 tot 2 cm
              lange stukjes. Hak de peterselie grof. Meng alle groenten en de
              peterselie in een schaal en kruid ze met een beetje zeezout en
              grove peper uit de molen. Bestrijk 4 stukken aluminiumfolie van 30
              x 50 cm met de walnootolie. Leg op elk stuk folie 1 zalmforelfilet
              en kruid. Breng op elke zalmforelfilet een vierde van het
              groentemengsel en wikkel de vis en de groenten stevig in de folie.
              Leg de pakketjes ongeveer 15 tot 20 minuten op een rooster in de
              oven op middelhoge temperatuur.
            </p>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
